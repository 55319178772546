import React, { useState } from 'react';
import Queston_Answer from "../constant/Queston_Answer";
import arrow_img from "../assets/icons/arrow.png";
// import '../pages/Accodion.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      return setActiveIndex(null);
    }
    setActiveIndex(index);
  };

  return (

    <div className='faqs_page'>
      <div className='heading'>
        <h2 className='page-title' align="center">
        Maaza Ganesh Chaturthi 2024
        </h2>
        <h4 className='page-sub-title' align="center">
          FAQs
        </h4>
      </div>
      <div className="wrapper">
        <div className="accordion">
          {Queston_Answer.map((item, index) => (
            <div key={index} className='item'>
              <div className='title' onClick={() => toggleAccordion(index)}>
                {/* <strong> */}
                <div className='question-style'>
                  <div className='question_Number'>
                    <h4>{index + 1}.</h4>
                    <p>
                      {item.question}
                    </p>
                    {/* <div>{activeIndex === index ? "-" : "+"}</div> */}
                  </div>
                  <div className='plus_minu'>
                    {/* <div>{activeIndex === index ? "-" : "+"}</div> */}
                    <div className='icon'>
                      {/* {activeIndex === index ? <IoIosArrowDown className="icon" /> : <IoIosArrowUp className="icon" />} */}
                      {activeIndex === index ? "-" : "+"}
                      {/* <IoIosArrowDown /> */}
                    </div>
                  </div>
                </div>
                {/* </strong> */}
              </div>
              <div className={activeIndex === index ? "content show" : "content"}>
                <p>
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Accordion
