import React, { useState } from 'react';
import "./Sidebar.css";
import { Link } from "react-router-dom";
import {
    FaBars,
    FaTimes

} from "react-icons/fa";


const Sidebar = ({ children }) => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
    const menuItem = [

        {
            title: "Terms and Conditions",
            path: "/",
            cName: "nav-text",
        },
        {
            title: "Privacy Policy",
            path: "/privacy",
            cName: "nav-text",
        },
        {
            title: "FAQs",
            path: "/faqs",
            cName: "nav-text",
        }
    ]
    return (
        <div >
            <div className="navbar">
                <Link to="#" className="menu-bars">
                    <FaBars onClick={showSidebar} />
                </Link>
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                    <li className="navbar-toggle">

                        <div className='close-btn'>
                            <Link to="#" className="menu-bars">
                                <FaTimes />
                            </Link>
                        </div>
                    </li>
                    {menuItem.map((item, index) => {
                        return (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;