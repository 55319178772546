import React from 'react'

const Terms = () => {
    return (
        <div className="tandc-page">
            <div className="tandc">
                <h3 className="page-title" align="center">
                    Terms and Conditions
                </h3>
                <h4 className='page-sub-title' align="center">
                    Maaza® Ganesh Utsav Promo 2024
                </h4>
                <div align="center">
                    <table border="1" cellspacing="0" cellpadding="0" width="669">
                        <tbody>
                            <tr>
                                <td width="81" valign="top">
                                    <p align="center">
                                        <strong>Clause</strong> <strong></strong>
                                    </p>
                                </td>
                                <td width="93" valign="top">
                                    <p align="center">
                                        <strong>Title</strong> <strong></strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        <strong>Terms and Conditions</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                    <strong>
                                        <p align="center">1</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>
                                            Promotion Details, Concept and    Participation
                                        </strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        <a name="_Hlk517722933">
                                            <strong>Maaza® Ganesh Utsav promotion</strong>
                                        </a>
                                        (“<strong>Promotion</strong>”) is a consumer promotion
                                        organised by Coca-Cola India Private Limited (“<strong>CCIPL</strong>”),
                                        wherein participants    (defined hereinbelow) upon
                                        participating in accordance with the terms and
                                        conditions herein stand a chance to win the Prize(s)
                                        mentioned in Clause 7, in    accordance with the terms
                                        and conditions mentioned herein. <strong></strong>
                                    </p>
                                    <p>
                                        The Promotion shall be    valid for the individuals
                                        residing in India except the state of Tamil Nadu
                                        <strong>(“Promotion Territory”)</strong>and may be
                                        accessible upon scanning QR codes    available on
                                        promotional material OR any PET pack of Maaza®
                                        (“<strong>Promotion Products</strong>”)<strong>.</strong>By
                                        participating in this Promotion, the Eligible Persons as
                                        per Clause 5 (each a    “<strong>Participant</strong>”)
                                        accepts and agrees to abide by these terms and
                                        conditions of the Promotion (“
                                        <strong>
                                            Promotion
                                        </strong>
                                        <strong>Terms</strong>”).
                                    </p>
                                    <p>
                                        It is hereby clarified that the Participant    may
                                        participate in the Promotion multiple times but shall be
                                        eligible to win the    Prizes as per Clause 7 during the
                                        Promotion Period. The Promotion may    be  offered,
                                        advertised and promoted, <em>inter alia</em>, through
                                        media including digital media, point of sale materials,
                                        public    announcements, website, and    other available
                                        media.
                                    </p>
                                    <p>
                                        The Promotion is subject to all    applicable central,
                                        state, and local laws and regulations.
                                    </p>
                                    <p>
                                        These Promotion terms and conditions (“<strong>Terms</strong>”)
                                        will be hosted at
                                        <strong>
                                            https://www.coke2home.com/maaza-promo-gc/
                                        </strong>
                                        (“<strong>Promotion Website</strong>”).
                                    </p>
                                    <p>
                                        Also, you can click here
                                        <strong>
                                            <u>https://www.coca-cola.com/in/en/media-center/coca-cola-indias-project-unnati</u>
                                        </strong>
                                        to know more about Project Unnati – Helping local
                                        farmers grow more mangoes.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">2</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>CCIPL and Partners</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        This Promotion is    organised  by CCIPL, having its
                                        corporate office at 16th floor, One Horizon Center, Golf
                                        Course Road, DFL    Phase V, Sector 43, Gurgaon.
                                    </p>
                                    <p>
                                        And
                                    </p>
                                    <p>
                                        The    operational part of the Promotion is managed by
                                        PREMIER SALES PROMOTIONS PRIVATE    LIMITED, a company
                                        governed under the Companies Act, 1956 having its
                                        registered office at Mitra Towers, 4 <sup>th</sup>
                                        Floor, Kasturba Road,    Bangalore 560 001 (hereinafter
                                        referred to as “<strong>Agency</strong>”)
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">3</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>
                                            Deemed acceptance of terms and conditions
                                        </strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        Entry and participation in    this Promotion will be
                                        deemed as an unconditional and an unequivocal
                                        acceptance of these Terms, including any modifications,
                                        revisions, and    amendments thereto. The decisions of
                                        CCIPL shall be final and binding on the    Participants
                                        in all matters related to the Promotion. Further, the
                                        Participants recognize that these Terms may be modified
                                        by CCIPL from time to    time without any prior/public
                                        notice by posting the applicable terms and    conditions
                                        on the Promotion Website. CCIPL also reserves the right
                                        to cancel    or terminate the Promotion at any stage
                                        without assigning any reasons    whatsoever.
                                        Successfully entering the Promotion and winning a Prize
                                        is    subject to all requirements set forth herein.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">4</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Promotion Period</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        <a name="_Hlk95730028">
                                            This    Promotion will commence at
                                            <strong>
                                                00:00:01 am
                                            </strong>
                                            Indian Standard Time (“<strong>IST</strong>”) on
                                            <strong>7 <sup>th</sup> Sept 2024</strong>(“<strong>Start
                                                Date</strong>”) and will continue until
                                            <strong>
                                                16 <sup>th</sup> Sept 2024 11:59:59
                                            </strong>
                                            <strong>pm</strong> IST (“<strong>End Date</strong>”)
                                        </a>
                                        <strong>(“Promotion Period”)</strong>.
                                    </p>
                                    <p>
                                        Stock of The Coca-Cola Company Products without the
                                        Promotion    offer will also be available across India.
                                    </p>
                                    <p>
                                        Further, certain stock of the Promotion Products may be
                                        available    before the Promotion Period or may continue
                                        to be available even after the    Promotion Period.
                                        However, prospective Participants understand and agree
                                        that    they will not be permitted to participate in the
                                        Promotion before    commencement of the Promotion Period
                                        and after the expiry of the Promotion    Period and
                                        further, agree not to raise any claim or complaint
                                        against CCIPL,    its affiliates or its agents in this
                                        respect.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">5</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Eligibility</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        Subject to the criteria of    Promotion Territory, entry
                                        and participation in this Promotion is open to
                                        individuals who satisfy each of the following criteria
                                        (“<strong>Eligible Persons</strong>”):
                                    </p>
                                    <p>
                                        (i)-      Must    have attained 18 (eighteen) years of
                                        age.
                                    </p>
                                    <p>
                                        (ii)-     own a valid cellular GSM or    CDMA phone
                                        connection and internet connection that is accessible at
                                        all times.
                                    </p>
                                    <p>
                                        (iii)-bear a good moral character and have not been
                                        implicated or named in    any legal proceedings (either
                                        civil or criminal).
                                    </p>
                                    <p>
                                        (iv)- are not management, employees, directors or
                                        contractors, or the    family of the management,
                                        employees, directors or contractors of CCIPL, their
                                        related entities or other agencies, firms or
                                        companies associated with or related to the Promotion
                                        (including suppliers of    Prizes).
                                    </p>
                                    <p>
                                        Please note that any entry received from    states other
                                        than the Promotion Territory shall be declared as null
                                        and void    and shall not be taken into consideration
                                        for determining the Winners of the    Prize.
                                    </p>
                                    <p>
                                        For the sake of clarity, this Promotion is not open to
                                        Non-Resident    Indians (NRIs), Overseas Citizens of
                                        India (OCIs) and Persons of Indian    Origin (PIOs).
                                    </p>
                                    <p>
                                        Eligible Persons    participating or seeking to
                                        participate in the Promotion shall individually    be
                                        referred to as “<strong>Participant</strong>” and
                                        collectively as “<strong>Participants</strong>”.
                                    </p>
                                    <p>
                                        If any Eligible Person has    listed his/her phone
                                        numbers on the National Do Not Call Registry (“<strong>NDNC
                                            Registry</strong>”), he/she shall    de-register from
                                        the NDNC Registry prior to participating in the
                                        Promotion,    till the completion of the Promotion
                                        (including availing of the Prize (if    applicable)
                                        under the Promotion). Such persons agree not to make any
                                        claim or    raise any complaint whatsoever against CCIPL
                                        in this respect. Please note    that persons intending
                                        to participate in the Promotion who have not
                                        de-registered themselves from the NDNC Registry shall
                                        also have no right to    make any claim or raise any
                                        complaints against CCIPL, if they do or do not
                                        receive any call or SMS with respect to their
                                        application, participation and    all other matters
                                        pertaining to the Promotion.
                                    </p>
                                    <p>
                                        By    participating in the Promotion, each Participant
                                        hereby agrees to receive:
                                    </p>
                                    <p>
                                        i.                  details and information on this
                                        Promotion, on their respective mobile phones, through
                                        SMS or voice calls,    during the entire tenure of the
                                        Promotion.
                                    </p>
                                    <p>
                                        <strong>ii.</strong>updates and announcements
                                        concerning CCIPL’s products and promotions, on their
                                        respective mobile phones    (through SMS or voice calls)
                                        and Email Ids. <strong></strong>
                                    </p>
                                    <p>
                                        <strong>(“Marketing Communication”)</strong>
                                        <strong>
                                        </strong>
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        The Participant may opt-out of receiving Marketing
                                        Communication from CCIPL at any time by    emailing or
                                        calling, using the contact information listed below. In
                                        such a    request, the Participant must indicate that
                                        he/she wishes to stop receiving    Marketing
                                        Communication from CCIPL.
                                    </p>
                                    <p>
                                        Email:
                                        <a href="mailto:indiahelpline@coca-cola.com">
                                            indiahelpline@coca-cola.com
                                        </a>
                                    </p>
                                    <p>
                                        Consumer Helpline: 1800-208-2653
                                    </p>
                                    <p>
                                        Timings:
                                    </p>
                                    <p>
                                        9:00am–9:00pm (Mon-Sat)
                                    </p>
                                    <p>
                                        9:00am-6:00pm (Sun)
                                    </p>
                                    <p>
                                        The Participant hereby agrees    that he/she has read
                                        and understood the Privacy Policy mentioned on the
                                        Promotion Website<strong></strong>and provides
                                        his/her unconditional consent to collection of his/her
                                        personal information    namely Name, Mobile Number,
                                        Email ID, Address, PAN number and Location.    Further,
                                        the Participant hereby provides his/her consent for
                                        storage and    processing of his/her personal
                                        information collected for the purposes of    sending
                                        communication including but not limited to Marketing
                                        Communication. <strong></strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">6</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Participating in the Promotion</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        The    Participant can participate subject to the
                                        following terms:
                                    </p>
                                    <p>
                                        1.           Scan the QR code on the Promotion Product
                                        to    visit the official Maaza® Whatsapp Channel.
                                    </p>
                                    <p>
                                        2.           Register with a valid mobile number and
                                        accept    these Terms and thePrivacy Policy to
                                        Participate in this Promotion.
                                    </p>
                                    <p>
                                        3.           Answer a few questions on the official
                                        Maaza® Whatsapp    channel to get a chance to win Daily
                                        Prize(s).
                                    </p>
                                    <p>
                                        4.           Participant can send an image of themselves
                                        celebrating Ganesh Utsav with their family     to enter
                                        lucky draw and get a chance to win the Bumper Prize. The
                                        sharing of such image is purely voluntary however,
                                        necessary for    participation for the Bumper Prize.
                                    </p>
                                    <p>
                                        5.           Winner(s) of the vouchers can redeem it
                                        using the    voucher code(s).
                                    </p>
                                    <p>
                                        6.           Redemption link(s) will be made available
                                        on    WhatsApp
                                    </p>
                                    <p>
                                        7.           Gold Coin winners will be reached out to
                                        directly    by the agency on their contact numbers.
                                    </p>
                                    <p>
                                        Additional    terms and conditions may be  applicable
                                        and available on redemption platform.
                                    </p>
                                    <p>
                                        <u>Other    terms &amp; conditions:</u> <u></u>
                                    </p>
                                    <p>
                                        1.           Participants    shall be able to win the P
                                        <strong>rize(s)</strong> as per Clause 7 during the
                                        Promotion    period.
                                    </p>
                                    <p>
                                        2.           Participants will also be notified via SMS
                                        of the    details of the Prize(s) won &amp; instructions
                                        on how to redeem their    Prize(s).
                                    </p>
                                    <p>
                                        3.           Winners    of Daily Prizes must redeem
                                        their vouchers latest by
                                        <strong>
                                            31 <sup>st</sup> December 2024
                                        </strong>
                                        to prevent forfeiture.
                                    </p>
                                    <p>
                                        4.           Winners    of gold coin will receive their
                                        prizes latest by 31 <sup>st</sup> December    2024
                                    </p>
                                    <p>
                                        5.           Any forfeiture  will be binding and final
                                        on the Winner(s).
                                    </p>
                                    <p>
                                        6.           Entry    by proxy is not permitted.
                                        Participants can only enter in their own name. The
                                        Participants shall be solely responsible for any local
                                        calls / STD charges    applicable by their telecom
                                        service providers on the Promotion entries.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">7</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Prizes and Winners</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        During    the Promotion Period, Participants on the
                                        basis of valid participation as per    the Terms of
                                        Clause 6, shall get a chance  to win one of the daily
                                        prizes and one    bumper prize  the following Prize(s)
                                        ("Prize(s)”):
                                    </p>
                                    <p>
                                        Daily    Prize(s): (up to a maximum one time per mobile
                                        number, during the Promotion    Period.)
                                    </p>
                                    <ul>
                                        <li>
                                            PhonePe Voucher worth Rs.10/-
                                        </li>
                                        <li>
                                            BookMyShow Voucher worth Rs. 500/-
                                        </li>
                                        <li>
                                            Makemytrip Voucher worth Rs.1000/-
                                        </li>
                                    </ul>
                                    <p>
                                        AND
                                    </p>
                                    <p>
                                        Bumper    Prize <strong>(</strong>up to a maximum one
                                        time per mobile number,    during the Promotion Period.
                                        <strong>)</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            5g Gold coin
                                        </li>
                                    </ul>
                                    <p>
                                        The    winners of the Prize(s) (each a “<strong>Winner</strong>”)
                                        may access details of their Prize(s)    under the My
                                        Winnings section of the Promotion Website.
                                    </p>
                                    <p>
                                        It is hereby clarified that the Participant    may
                                        participate in the Promotion multiple times, but shall
                                        be able to win the    prize only once during the
                                        Promotion Period.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">8</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>
                                            Selecting and contacting Eligible Winners
                                        </strong>
                                        <strong></strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        (i)-             The Participants will be    eligible to
                                        receive the Prizes subject to the Terms detailed herein.
                                    </p>
                                    <p>
                                        (i)-      The Winner(s) will be    selected through  a
                                        computerized random    selection from the pool of valid
                                        submissions/ entries in accordance with    these Terms
                                        &amp; Conditions.
                                    </p>
                                    <p>
                                        (ii)-            The Participants    acknowledge not to
                                        raise any claim or complaint against CCIPL due to
                                        technical failure or any other causes beyond the control
                                        of CCIPL.
                                    </p>
                                    <p>
                                        (iii)-       Daily Prize Winner(s)    shall redeem their
                                        Prize(s) latest by 31 <sup>st</sup> December 2024 to
                                        avoid    forfeiture.
                                    </p>
                                    <p>
                                        (iv)-           The    Prize(s) once awarded cannot be
                                        replaced under    any circumstances.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">9</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Conditions relating to the Prize</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        (i)-             CCIPL shall not be held    responsible
                                        for any deficiency, defect, inadequacy, or irregularity
                                        in the    Prize(s). The Winner(s) may not exchange or
                                        assign the Prizes and shall not    be entitled to any
                                        compensation whether in cash or kind from CCIPL.
                                    </p>
                                    <p>
                                        (ii)-            No portion of the Prize(s)    offered
                                        under the Promotion is redeemable for cash or kind from
                                        CCIPL. The    Prizes are also not refundable or
                                        transferable and cannot be exchanged.
                                    </p>
                                    <p>
                                        (iii)-       No Prize will be    awarded if the
                                        information presented by the Participant(s) at the time
                                        of    entering the Promotion, or at any subsequent stage
                                        is factually incorrect.
                                    </p>
                                    <p>
                                        (iv)-           In the event of death of the    Winner,
                                        no claim from the nominee or his/her legal heirs of the
                                        Winner shall    be entertained for receiving the Prize.
                                    </p>
                                    <p>
                                        (v)-                If any Participant has any
                                        grievance with respect to the Prize, he/she may directly
                                        contact the    manufacturer.
                                    </p>
                                    <p>
                                        (vi)-              It is to be noted that in the
                                        event the Winner does not take the Prize in the time and
                                        manner stipulated,    then the Prize shall be forfeited
                                        by the Winner and cash or any monetary benefits    in
                                        kind shall not be awarded in lieu of the    Prize.
                                        Prizes must be taken as offered and cannot be varied.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">10</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Publicity</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        The    Participants undertake and irrevocably and
                                        unconditionally permit CCIPL to    cover the Promotion
                                        through various media including newspapers, radio,
                                        television including news and other channels, internet,
                                        point of sale    materials, etc., and shall not raise
                                        any objection, protest, or demur    whatsoever to such
                                        coverage or in this regard. Further, the Participants
                                        also    understand and agree that their responses may be
                                        publicised as part of this    Promotion.
                                    </p>
                                    <p>
                                        The    Winners must, at the request of CCIPL,
                                        participate in all promotional    activity (such as
                                        publicity and photography) surrounding the winning of
                                        the    Prize, free of charge, and they consent to CCIPL
                                        using their name and image    in all promotional
                                        materials.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">11</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Intellectual Property</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        All right, title, and interest, including but not
                                        limited to the Intellectual Property Rights, in the
                                        promotional material(s),    and in any and all responses
                                        received, or other materials created during or    for
                                        the Promotion, as a part of the Promotion or otherwise,
                                        shall vest solely    and exclusively with CCIPL at all
                                        times. CCIPL or any person or entity    permitted by
                                        CCIPL in this regard shall be entitled to use any
                                        information in    connection with the entry in any media
                                        for future promotional, marketing,    publicity and any
                                        other purpose, without any permission and or payment to
                                        the    Participant. CCIPL may use    the material in any
                                        medium in any reasonable manner it sees fit. Copyright
                                        in    any such material remains the sole property of
                                        CCIPL.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">12</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>General Conditions</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        <strong><u>General Terms and Conditions:</u></strong>
                                    </p>
                                    <p>
                                        ·           Each Participant represents and warrants
                                        that he/she is legally competent to enter into binding
                                        contracts under    applicable laws. By taking part
                                        and/or entering into the Promotion the    Participant
                                        warrants that all information provided by Participant
                                        regarding    Participant's name, age, state, city,
                                        address, phone number, etc., is true,    correct,
                                        accurate and complete.
                                    </p>
                                    <p>
                                        ·           The decision of CCIPL or the Agency, as
                                        regards the selection of winners and distribution of the
                                        prizes shall be    final, subject only to CCIPL ‘s
                                        approval. No correspondence or any other    claims
                                        whatsoever, in this regard will be entertained.
                                    </p>
                                    <p>
                                        ·           No queries, claims, dispute, complaints or
                                        grievances shall be entertained by CCIPL and the Agency
                                        after 60 days from    the date of the closure of the
                                        Promotion.
                                    </p>
                                    <p>
                                        ·           The prize cannot be exchanged and is not
                                        transferable and cannot be converted into cash.
                                    </p>
                                    <p>
                                        ·           The Agency shall provide the prize on an
                                        "as-is basis" and without any warranty or guarantee
                                        concerning the    quality, suitability or comfort, and
                                        CCIPL     and/or its associates, affiliates and/ or its
                                        management, directors,    officers, agents,
                                        representatives shall not be responsible for or liable
                                        (including but not limited to the product and service
                                        liabilities) for    deficiency and/ or defect of any
                                        product/service and/or the prize or for any    kind of
                                        consequential damages/ loss, in any manner whatsoever.
                                    </p>
                                    <p>
                                        ·           CCIPL reserves the right to change, defer,
                                        alter or cancel this Promotion in part or full, or
                                        change any or all of the    terms and conditions that
                                        are applicable, without giving prior
                                        intimation/notice of any kind and will not be liable for
                                        any consequential    losses/damages. Any decision of
                                        CCIPL in this regard and in general with    respect to
                                        this Promotion and the terms and conditions thereto
                                        shall be    final, binding and non-Offer able.
                                    </p>
                                    <p>
                                        ·           Nothing contained herein amounts to a
                                        commitment or representation by CCIPL to conduct further
                                        Promotion.
                                    </p>
                                    <p>
                                        ·           CCIPL reserves the right to change any
                                        details of the Promotion, including but not limited to
                                        amending these terms    and conditions, the type of
                                        Promotion, submission mechanism, eligibility
                                        criteria, term of the Promotion, and the prizes for the
                                        Promotion. CCIPL    reserves the right to amend,
                                        temporarily suspend or withdraw the Promotion in    its
                                        absolute discretion provided that CCIPL shall not
                                        exercise this right    unreasonably.
                                    </p>
                                    <p>
                                        ·           CCIPL     shall not be liable to
                                        award/provide the prize(s) and/or declare the    winners
                                        of the prizes of the Promotion     due to any cause
                                        beyond its reasonable control or foresight, including
                                        but not limited to, act of god, insurrection or civil
                                        disorder, religious    strife, war or military
                                        operations, terrorist act, partial or total strikes,
                                        either internal or external, lock-out, epidemic,
                                        blockage of means of    transport or of supplies,
                                        national or local emergency, earthquake, fire,    storm,
                                        flood, water damage, governmental, regulatory or legal
                                        restrictions,    acts or omissions of persons for whom
                                        CCIPL is not responsible.
                                    </p>
                                    <p>
                                        ·           The decision of CCIPL shall be final in all
                                        regards and no communication shall be entertained in
                                        this regard. None of the    provisions of these Terms
                                        and Conditions shall be deemed to constitute a
                                        partnership or agency between any Participant and CCIPL
                                        and the Participant    shall not have the authority to
                                        bind CCIPL in any manner whatsoever.
                                    </p>
                                    <p>
                                        ·           CCIPL or any of its respective
                                        officers/employees/directors shall not be responsible
                                        for delayed, lost,    mutilated, corrupted or illegible
                                        documents etc.
                                    </p>
                                    <p>
                                        ·           CCIPL or the Agency shall not be liable to
                                        perform any of its/their respective obligations under
                                        the Promotion or in    respect of the prize where it is
                                        unable to do so as a result of circumstances    beyond
                                        its/their control in the nature of fire, explosions,
                                        natural    calamities, state emergency, riots, or any
                                        other Force Majeure condition,    etc., and shall not be
                                        liable to compensate the Participant in these
                                        circumstances.
                                    </p>
                                    <p>
                                        ·           CCIPL or the Agency shall not be
                                        accountable/liable for any
                                        disruptions/stoppages/interruptions or    cancellation
                                        of the Promotion due to any government restrictions.
                                    </p>
                                    <p>
                                        ·           Failure to exercise or delay in exercising
                                        a right or remedy provided hereunder or by law does not
                                        constitute a waiver    of the right or remedy or waiver
                                        of other rights or remedies on the part of    CCIPL or
                                        the Agency.
                                    </p>
                                    <p>
                                        ·           CCIPL or the Agency accepts no
                                        responsibility for late, lost, incomplete, incorrectly
                                        submitted, delayed,    illegible, corrupted or
                                        misdirected entries, loss of SMS, loss of internet
                                        connectivity, unique codes, documents, demand drafts,
                                        claims or correspondence    whether due to error,
                                        omission, alteration, tampering, unauthorized data
                                        hacking, deletion, theft, destruction, virus attack,
                                        transmission    interruption, communications failure,
                                        hardware failure or otherwise. CCIPL or    the Agency
                                        shall not be liable for any consequences of user error
                                        including    (without limitation) costs incurred.
                                    </p>
                                    <p>
                                        ·           If a Participant is dissatisfied with the
                                        Promotion or the Promotion rules and/or any content or
                                        any requirements of    the Promotion form, the materials
                                        available related thereto, or with these    terms and
                                        conditions, his/her sole and exclusive remedy is to not
                                        participate    in the Promotion.
                                    </p>
                                    <p>
                                        ·           If a person chooses to access the Promotion
                                        Website from outside India, he/she shall do so on
                                        his/her own initiative and    shall be responsible for
                                        compliance with applicable local laws of the    country.
                                    </p>
                                    <p>
                                        ·           The operational part of the scheme like
                                        redemption of prize &amp; dispatches shall be managed by
                                        the Agency; However,    promotional product will be
                                        distributed through CCIPL Distributor/dealer.
                                    </p>
                                    <p>
                                        ·           The participation in the Promotion is
                                        purely voluntary and the same shall be construed as an
                                        acceptance of the    terms and conditions stipulated
                                        herein.
                                    </p>
                                    <ul>
                                        <li>
                                            Release and Limitations of Liability:
                                        </li>
                                    </ul>
                                    <p>
                                        (i)                          CCIPL and the Agency shall
                                        under no    circumstances be liable, whether jointly or
                                        severally, for any loss or damage    suffered or
                                        sustained (including but not limited to consequential
                                        loss),    including for personal injury or property
                                        damage suffered or sustained, as a    result of
                                        acceptance of any prize.
                                    </p>
                                    <p>
                                        (ii)                       By entering the Promotion,
                                        the Participant    hereby releases from and agrees to
                                        indemnify CCIPL, and/ or any of its    respective
                                        officers/employees from and against all liability, cost,
                                        loss or    expense arising out of acceptance of the
                                        prize or participation in the    Promotion including
                                        (but not limited to) personal injury and damage to
                                        property and whether direct, indirect, consequential,
                                        foreseeable, due to    some negligent act or omission,
                                        or otherwise.
                                    </p>
                                    <p>
                                        (iii)                     CCIPL and the Agency accept no
                                        liability,    whether jointly or severally, for any
                                        errors or omissions, whether on behalf    of itself,
                                        Agency or third parties in relation to the prizes.
                                    </p>
                                    <p>
                                        (iv)                      The Participant shall be
                                        solely responsible    for any consequences which may
                                        arise due to his/her participation in the    Promotion
                                        by conducting an illegal act. Participant also
                                        undertakes to    indemnify CCIPL and/or the Agency and
                                        their respective officers, directors,    employees and
                                        agents on the happening of such an event (including
                                        without    limitation cost of attorney, legal charges
                                        etc.) on full indemnity basis for    any loss/damage
                                        suffered by CCIPL or the Agency on account of such act
                                        on the    part of the Participant.
                                    </p>
                                    <p>
                                        (v)                        In consideration of CCIPL
                                        allowing the Participant(s) to take part in    the
                                        Promotion , to the maximum extent permitted by law, the
                                        Participant(s)    waive and release each and every right
                                        or claim, all actions, causes of    actions (present or
                                        future) each of them has or may have against CCIPL  ,
                                        its respective agents, directors,    officers, business
                                        associates, group companies, sponsors, employees, or
                                        representatives for all and any injuries, accidents, or
                                        mishaps (whether    known or unknown) or (whether
                                        anticipated or unanticipated) arising out of    the
                                        Promotion  or related to the    Promotion  or the prize
                                        of the    Promotion.
                                    </p>
                                    <p>
                                        Additional    General Terms and Conditions
                                    </p>
                                    <p>
                                        ·           This Promotion is not available to
                                        employees, of CCIPL and the Agency, its clients or
                                        agencies and their    immediate families.
                                    </p>
                                    <p>
                                        ·           Any costs associated with entering the
                                        Promotion, including accessing the Promotion Website,
                                        are the responsibility    of each Participant. Also,
                                        regular call and message rates will apply as per    the
                                        consumer telecom operator in their respective circles.
                                    </p>
                                    <p>
                                        ·           The Agency is acting as an executing agency
                                        for this Promotion and holds no legal and financial
                                        liability on unclaimed    Prize (s), incentives, etc.
                                        beyond the applicable period of the Promotion.
                                    </p>
                                    <p>
                                        ·           This Promotion is subject to promotional
                                        availability and government regulations.
                                    </p>
                                    <p>
                                        ·           The Agency &amp; CCIPL reserves the right
                                        to change the terms and conditions of this scheme,
                                        including extending,    withdrawing, or discontinuing
                                        the same without notice, without assigning any
                                        reason, at their sole discretion and without any
                                        liability.
                                    </p>
                                    <p>
                                        ·           If this promotion is interfered with in any
                                        way or is not capable of being conducted as reasonably
                                        anticipated due to any    reason beyond the reasonable
                                        control of the Agency, including but not limited    to
                                        technical difficulties, unauthorized intervention or
                                        fraud, the Agency    reserves the right, in its sole
                                        discretion, to the fullest extent permitted    by law
                                        (a) to invalidate any customer; or (b) subject to any
                                        written    directions from a regulatory authority, to
                                        modify, suspend, terminate or    cancel the promotion,
                                        as appropriate.
                                    </p>
                                    <p>
                                        ·           The Agency or CCIPL is not responsible for
                                        any problems or technical malfunction of any telephone
                                        or network or lines,    servers or providers, computer
                                        equipment, software, technical problems or    traffic
                                        congestion on a mobile network, or any combination
                                        thereof, or any    other technical failures including
                                        any damage to Participant's or any other    person's
                                        mobile handset or computer related to, or resulting
                                        from,    participation in this Promotion or the
                                        downloading of any materials related    to this
                                        Promotion
                                    </p>
                                    <p>
                                        ·           If for any reason this Promotion is not
                                        capable of running as planned because of infection by
                                        computer virus, bugs,    tampering, unauthorized
                                        intervention, technical failures or any other causes
                                        beyond the reasonable control of the Agency which
                                        corrupt or affect the    administration, security,
                                        fairness, integrity or proper conduct of this
                                        Promotion, the Agency or CCIPL reserves the right in its
                                        sole discretion to    cancel, terminate, modify or
                                        suspend the Promotion, subject to any written
                                        directions made under applicable State or Territory
                                        legislation.
                                    </p>
                                    <p>
                                        ·           Caution: Any attempt to deliberately damage
                                        the Agency’s Promotion Website or the information on the
                                        Promotion Website,    to cause malicious damage or
                                        interference with the normal functioning of the
                                        Promotion Website, or to otherwise undermine the
                                        legitimate operation of this    Promotion may be a
                                        violation of criminal and civil laws and should such an
                                        attempt be made, whether successful or not, the Agency
                                        reserves the right to    seek damages to the fullest
                                        extent permitted by law. If the Agency suffers    loss
                                        or incurs any costs in connection with any breach of
                                        these Terms or any    other legal obligation by a
                                        Participant, the Participant agrees to indemnify    the
                                        Agency for those losses, damages, and costs.
                                    </p>
                                    <p>
                                        ·           The Agency, its clients, agents, and
                                        distributors accept no responsibility, financial or
                                        otherwise for the misuse    of the Prize(s)and are not
                                        liable for any personal loss or injury and cannot    be
                                        held responsible for any disagreement concerning
                                        quality/ availability of    the Prize(s).
                                    </p>
                                    <p>
                                        ·           CCIPL does not endorse the quality of any
                                        of the products or services being offered by the Agency
                                        or by the Promotion    partner. Any complaints or
                                        queries pertaining to the same would have to be    taken
                                        up directly with the Agency marketing of such a product
                                        or service.
                                    </p>
                                    <p>
                                        ·           The Agency, its clients, agents, and
                                        distributors cannot replace any lost, stolen or damaged
                                        Prize(s) and reserve    the right to withdraw or amend
                                        any details and/or prices without notice.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">13</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Release and Limitations of Liability</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        (i)               By    entering the Promotion, the
                                        Participants hereby release from and agree to
                                        indemnify CCIPL, and/ or any of their respective
                                        officers/employees from and    against all liability,
                                        cost, loss or expense arising out of acceptance of the
                                        Prize or participation in the Promotion including (but
                                        not limited to)    personal injury and damage to
                                        property and whether direct, indirect,    consequential,
                                        foreseeable, due to some negligent act or omission, or
                                        otherwise.
                                    </p>
                                    <p>
                                        (ii)              CCIPL    accept no liability, whether
                                        jointly or severally, for any errors or    omissions,
                                        whether on behalf of themselves or third parties in
                                        relation to    the Prizes. CCIPL, and any of their
                                        respective parent companies,    subsidiaries,
                                        affiliates, directors, officers, professional advisors,
                                        employees and agencies will not be responsible for any
                                        late, lost, misrouted,    or distorted or damaged
                                        transmissions or entries.
                                    </p>
                                    <p>
                                        (iii)            The    Participant(s) shall be solely
                                        responsible for any consequences which may    arise due
                                        to their participation in the Promotion by conducting an
                                        illegal    act. Participant(s) also undertakes to
                                        indemnify CCIPL and their respective    officers,
                                        directors, employees and agents on the happening of such
                                        an event    (including without limitation cost of
                                        attorney, legal charges etc.) on full    indemnity basis
                                        for any loss/damage suffered by CCIPL on account of such
                                        act    on the part of the Participant. Participant(s)
                                        hereby agree to indemnify,    defend and hold harmless
                                        CCIPL in case of any loss or liability (including but
                                        not limited to liabilities, judgments, damages, losses,
                                        fines and penalties, claims, costs and expenses such as
                                        attorneys’ fees and    expenses) or any other loss
                                        associated with, arising out of or incidental to
                                        their ineligibility to participate, any untoward, rash
                                        or negligent act on    their part, or non-compliance or
                                        alleged non-compliance with these Terms, any    other
                                        rules, regulations, directives (whether written or
                                        verbal) issued by    CCIPL from time to time, during the
                                        Promotion.
                                    </p>
                                    <p>
                                        (iv)            To    the fullest extent permitted by
                                        law, in no event will CCIPL or any of its    officers,
                                        servants, employees, representatives and/or agents
                                        (including    without limitation, any third party
                                        service providers that CCIPL may engage    for purposes
                                        of the Promotion), be liable for any loss or damages
                                        (including,    without limitation loss of income,
                                        profits or goodwill, indirect, incidental,
                                        consequential, exemplary, punitive or special damages of
                                        any party including    third parties) howsoever arising
                                        whether in contract, tort, negligence or    otherwise,
                                        in connection with the Promotion and/or the Promotion
                                        Prizes, even    if CCIPL has been advised of the
                                        possibility of such damages in advance, and    all such
                                        damages are expressly excluded. The Participant shall
                                        indemnify,    defend, and hold CCIPL harmless in the
                                        event of there being any third
                                        party/entity/organization claims arising from or related
                                        to his/her    participation in the Promotion. In no
                                        event shall CCIPL and be liable to any    Participant
                                        for acts or omissions arising out of or related to the
                                        Promotion    or his/her participation in the Promotion.
                                    </p>
                                    <p>
                                        (v)              In    consideration of CCIPL allowing
                                        the Participant(s) to take part in the    Promotion, to
                                        the maximum extent permitted by law, the Participant(s)
                                        waive    and release each and every right or claim, all
                                        actions, causes of actions    (present or future) each
                                        of them has or may have against CCIPL, its    respective
                                        agents, directors, officers, business associates, group
                                        companies,    sponsors, employees, or representatives
                                        for all and any injuries, accidents,    or mishaps
                                        (whether known or unknown) or (whether anticipated or
                                        unanticipated)    arising out of the Promotion or
                                        related to the Promotion or the Prizes of the
                                        Promotion.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="81" valign="top">
                                <strong>
                                        <p align="center">14</p>
                                    </strong>
                                </td>
                                <td width="93" valign="top">
                                    <p>
                                        <strong>Dispute</strong>
                                    </p>
                                </td>
                                <td width="495" valign="top">
                                    <p>
                                        (i)                The courts of competent jurisdiction
                                        at Gurgaon    shall have exclusive jurisdiction to
                                        determine any and all disputes arising    out of, or in
                                        connection with, the Promotion.
                                    </p>
                                    <p>
                                        (ii)              Participation in the Promotion is
                                        unauthorized in    any jurisdiction that does not give
                                        effect to all provisions of these Terms,    including
                                        without limitation, this clause.
                                    </p>
                                    <p>
                                        (iii)             Further all issues and questions
                                        concerning the    construction, validity, interpretation
                                        and enforceability of these Terms, or    the rights and
                                        obligations of the Participants and/or CCIPL and/or
                                        partners    of this Promotion, shall be governed and
                                        construed in accordance with the    laws of the Republic
                                        of India.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Terms
