import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import FAQs from './pages/FAQs.jsx';
import Privacy from './pages/Privacy.jsx';
import Terms from './pages/Terms.jsx';
import './MediaQuery.css';
import Accordion from './pages/Accordion.js';

const App = () => {
  return (
    // <div className='wrapper'>
    //             <Accordion />
    //           </div>
   
     <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
      <div className='App'>
        <Sidebar>
          <Routes>
            <Route path="/" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            {/* <Route path="/faqs" element={<FAQs />} /> */}
            <Route path="/faqs" element={<Accordion />} />
          </Routes>
        </Sidebar>
      </div>
    </BrowserRouter> 

  );
};

export default App;