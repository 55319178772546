const Queston_Answer = [
    {
      question: 'What is Maaza Ganesh Chaturthi Promo?',
      answer: 'Maaza Ganesh Chaturthi Promo is an online promotion activity where on purchase of Maaza specified SKU’s*, consumers can participate and stand a chance to win the following: -5g Gold Coins  -MakeMyTrip Vouchers worth Rs. 1000 -BookMyShow Voucher worth Rs. 500 -PhonePe Gift Card worth Rs. 10 ',
    },
    {
      question: 'What is the promotion duration & timings?',
      answer: 'This Promotion will commence at 00:00:01 am Indian Standard Time (“IST”) on 7th September 2024 (“Start Date”) and will continue until 16th September 2024 at 11:59:59 pm IST (“End Date”). Any entries received outside the Promotion Period shall not be eligible for the Prize(s) and shall not be carried forward to any other promotional scheme ahead.',
    },
    {
      question: '*On which product is this promo applicable?',
      answer: 'The offer is applicable on purchase of any 250 ml, 400 ml, 600 ml, 750 ml, 1.25 L PET packs of Maaza brand only.',
    },
    {
      question: 'How do I participate?',
      answer: "Step1:  Buy Maaza Promotion Pack. Step2: Scan the QR Code on the promotion pack. Step3: Land on WhatsApp and send a predefined message to kick off your journey. Answer the  quiz and seize the opportunity to win fabulous daily rewards! Step4 : Submit a Photo of your family with the Ganesh Chaturthi celebrations at home & Get a chance to win the Bumper reward- 5g Gold Coins",
    },
    {
      question: 'What are the prizes available to be won?',
      answer: 'Participants can win following Prize(s): -5g Gold Coins  -MakeMyTrip Vouchers worth Rs. 1000  -BookMyShow Voucher worth Rs. 500  -PhonePe Gift Card worth Rs. 10',
    },
    {
      question: 'How many times can a participant win during this promo?',
      answer: 'Participants can win only once during the entire promotion. However, participants who have won PhonePe, MakeMyTrip, or BookMyShow gift cards are still eligible to win the Bumper Prize if they submit a photo of their family with the Ganesh Chaturthi celebrations at home.',
    },
    {
      question: 'How Can I redeem PhonePe Gift Card?',
      answer: 'Step 1:  Once you receive a PhonePe Gift Card, you can add it to your PhonePe account. Step 2: Go to My Profile and select Payment Methods. Step 3: Choose PhonePe Gift Card and click on Claim Now. Step 4 : Enter the gift card number and PIN to add the balance to your account.',
    },
    {
      question: 'How Can I redeem BookMyShow Gift Card?',
      answer: 'Step 1:  Once you receive a BookMyShow Gift Card, Login to the BookMyShow app and make your booking. Step 2: On the Booking Confirmation page, click on Unlock Offers or Apply Promo Codes. Step 3: Enter your offer code and click on Claim Now. Step 4: The discount will be applied to your booking.',
    },
    {
      question: 'How Can I redeem MakeMyTrip Gift Card?',
      answer: 'Step 1:  Once you receive a MakeMyTrip Gift Card, Login to the MakeMyTrip app and make your booking. Step 2: Select your Flight/ Hotel and fill required details till you reach the payment page. Step 3: On the app,choose Gift card as the payment option. Step 4 :Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No. Step 5: Click on Make payments and pay the balance amount if any using other Payment Modes listed.',
    },
    {
      question: 'Is everyone who completes their journey eligible to win the bumper prize?',
      answer: 'Participants are eligible to win bumper prize if they submit a photo of their family with the Ganesh Chaturthi celebrations at home.',
    },
    {
      question: 'How will I know if I am a Gift Card winner?',
      answer: 'If you are a Gift Card winner, you will be notified instantly on WhatsApp, along with the reward code and a link.',
    }
  ];

export default Queston_Answer;